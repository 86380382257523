import React, { useEffect, useRef } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./iscwest2025.css"

function ScoreApp() {
  useEffect(function createStyle() {
    // Create a new style element
    const style = document.createElement("style")
    style.innerHTML = `
          .sa-full>iframe {
                padding-top: 3.5rem;
                position: relative !important;

                @media screen and (min-width: 640px) {
                    padding-top: 6rem;
                }
            }
        `

    // Append the style element to the document's head
    document.body.appendChild(style)

    return () => {
      // Cleanup: Remove the style element on unmount
      document.body.removeChild(style)
    }
  }, [])

  useEffect(() => {
    const script = document.createElement("script")
    script.src =
      "https://static.scoreapp.com/js/integration/v1/embedding.js?v=cQozBb"
    script.async = true

    const scoreAppContainer = document.getElementById("score-app")
    if (!scoreAppContainer) return

    // Append the script to the body
    scoreAppContainer.appendChild(script)

    // Cleanup function to remove the script on unmount
    return () => {
      scoreAppContainer.removeChild(script)
    }
  }, [])

  return (
    <div
      className="container"
      id="score-app"
      style={{
        minHeight: "calc(100vh - 400px)",
        position: "relative",
        flex: "1 0 auto",
        marginBottom: "4rem",
        zIndex: 1,
      }}
      data-sa-url="https://c4a9b296-064c-45f9-a231-38a8d77307bc.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
      data-sa-view="full"
    ></div>
  )
}

function ISCWest2025(props) {
  const iframeRef = useRef(null)

  return (
    <Layout location={props.location}>
      <SEO
        title="ISC West 2025 | Quandary Consulting Group"
        description="With personalized insights, you'll get a clearer picture of how AI fits into your business – and where your tech stack may need an upgrade."
      />
      <section className="relative flex-grow">
        <iframe
          className="isc-score-app"
          ref={iframeRef}
          src="https://c4a9b296-064c-45f9-a231-38a8d77307bc.scoreapp.com/?sa_hide_header=1&sa_hide_footer=1&sa_target=_top"
          width="100%"
          height="max"
          style={{
            border: "none",
            position: "relative",
            display: "block",
            width: "100%",
            paddingTop: "5rem",
            overflow: "scroll",
            transition: "height 0.3s ease",
          }}
          scrolling="no"
          title="ISC West 2025"
        />
      </section>
    </Layout>
  )
}

export default ISCWest2025
